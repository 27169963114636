import { Component, OnInit } from '@angular/core'
import { ProfileInfo, ProfileService } from '../../../layouts'
import { VERSION } from '../../../app.constants'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'jhi-app-version',
    styles: [
        `
            strong {
                font-size: 11px;
            }
        `
    ],
    template: `
        <strong
            *ngIf='version || backendVersion'
            class='text-muted navbar-version'
        >
            <span *ngIf='version'>UI {{ version }}</span>
            <span *ngIf='version && backendVersion'> | </span>
            <span *ngIf='backendVersion'>API {{ backendVersion }}</span>
        </strong>
    `
})
export class AppVersionComponent implements OnInit {
    version: string = VERSION
    backendVersion = ''

    constructor(private readonly profileService: ProfileService) {}

    public ngOnInit(): void {
        this.profileService
            .getProfileInfo()
            .pipe(untilDestroyed(this))
            .subscribe((profileInfo: ProfileInfo): void => {
                this.backendVersion = profileInfo.backendVersion
            })
    }
}
