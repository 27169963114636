import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { flatMap } from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Material, MaterialSubGroup } from '../../entities'
import { MaterialGroup } from '../../entities/material-group.model'
import { Product } from '../../entities/product.model'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'
import { StateStorageService, TitleService } from '../../shared'
import { DeleteCustomTagsPipe } from '../../shared/pipe/delete-custom-tags.pipe'
import { BaseComponent } from '../base/base.component'
import { contentContainerRequest$ } from '../common/content-container-request'
import { PdfCollectorService } from '../common/pdf-collector.service'
import { SelectTableOfContentsAction } from '../redux/actions/book-navigation.action'
import { StartLoadingAction, StopLoadingAction } from '../redux/actions/loading.action'
import { SetGalleryDataAction, SetPdfDataAction, ToggleSidebarAction } from '../redux/actions/release.action'
import { BookState } from '../redux/book.state'
import { shouldShowSidebar } from '../redux/state'
import { MaterialGroupService } from './material-group.service'

@UntilDestroy()
@Component({
    selector: 'material-group-detail',
    templateUrl: './material-group-detail.component.html',
    styleUrls: ['./material-group-detail.component.scss'],
    providers: [DeleteCustomTagsPipe]
})
export class MaterialGroupDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    public materialGroup: MaterialGroup

    public materials: Material[][]

    public productMap: Map<string, Map<string, Product[]>>

    public relations: string[]

    public hasMaterials = false

    public hasProducts = false

    public hasImages = false

    public hasLinkedAttachmentCategories = false

    public hasMaterialCategories = false

    public hasFiles = false

    public hasLoaded = false

    public activeIds: string[] = []

    public secondaryActiveIds: string[] = []

    public showSidebar$: Observable<boolean>

    public language: string

    public bookElement = 'material-group'

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>

    constructor(
        private readonly materialGroupService: MaterialGroupService,
        private readonly titleService: TitleService,
        store: Store<BookState>,
        private readonly pdfCollectorService: PdfCollectorService,
        private readonly stateStorageService: StateStorageService,
        private readonly deleteCustomTagsPipe: DeleteCustomTagsPipe
    ) { super(store) }

    ngOnInit(): void {
        super.ngOnInit()
        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))
        this.store.dispatch(new SelectTableOfContentsAction())

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {

                        const materialGroupId = parseInt(params.id, 10)
                        const currentReleaseId = currentRelease.id

                        this.language = params.language

                        // We only need to call the backend if the material group has actually changed or a release is selected
                        if (!this.materialGroup || materialGroupId !== this.materialGroup.originalId || selectedRelease) {

                            if (selectedRelease && selectedRelease.id !== currentReleaseId) {
                                return this.loadMaterialGroup(currentReleaseId, materialGroupId, selectedRelease.id)
                            } else {
                                return this.loadMaterialGroup(currentReleaseId, materialGroupId)
                            }

                        } else {
                            this.titleService.setTitle(this.materialGroup.heading)
                        }

                        return of(this.materialGroup)

                    }
                }),
                untilDestroyed(this)

            )
            .subscribe((materialGroup: MaterialGroup) => {
                this.handleMaterialGroup(materialGroup)

            })
    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    private loadMaterialGroup(releaseId: number, originalId: number, selectedReleaseId?: number) {

        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.materialGroupService.find(releaseId, originalId, selectedReleaseId)
    }

    private handleMaterialGroup(materialGroup: MaterialGroup) {
        this.materialGroup = materialGroup
        // Split materials into two columns
        const materials = flatMap(materialGroup.materialSubGroups, (msg: MaterialSubGroup) => msg.materials)

        const halfWayThough = Math.ceil(materials.length / 2)

        const firstColumn = materials.slice(0, halfWayThough)
        const secondColumn = materials.slice(halfWayThough, materials.length)

        this.materials = [firstColumn, secondColumn]

        this.hasMaterials = materials.length > 0
        this.hasProducts = this.materialGroup.products && this.materialGroup.products.length > 0
        this.hasImages = this.materialGroup.images.length > 0
        this.hasFiles = this.materialGroup.pdfs.length > 0 || this.materialGroup.files.length > 0
        this.hasLinkedAttachmentCategories = this.materialGroup.linkedAttachmentCategories.length > 0
        this.hasMaterialCategories = this.materialGroup.materialCategories.length > 0

        this.titleService.setTitle(this.deleteCustomTagsPipe.transform(this.materialGroup.heading))

        this.hasLoaded = true
        this.store.dispatch(new SetGalleryDataAction(this.materialGroup.images))

        const files = this.pdfCollectorService.collectPdfs(this.materialGroup)
        this.store.dispatch(new SetPdfDataAction(files))

        this.store.dispatch(new StopLoadingAction())

        this.setInitiallyOpenedPanel()
        this.setSecondaryInitiallyOpenedPanel()
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    setInitiallyOpenedPanel(): void {
        this.activeIds = []

        if (this.hasMaterialCategories) {
            this.activeIds = ['ngb-panel-material-categories']
        } else if (this.hasMaterials) {
            this.activeIds = ['ngb-panel-materials']
        } else if (this.hasProducts) {
            this.activeIds = ['ngb-panel-products']
        }
    }

    setSecondaryInitiallyOpenedPanel(): void {
        this.secondaryActiveIds = []

        if (this.hasImages) {
            this.secondaryActiveIds = ['ngb-panel-images']
        } else if (this.hasFiles) {
            this.secondaryActiveIds = ['ngb-panel-files']
        } else if (this.hasLinkedAttachmentCategories) {
            this.secondaryActiveIds = ['ngb-panel-attachment-categories' + 0]
        }
    }
}
