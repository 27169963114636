import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core'

@Directive({
  selector: '[jhiHighlight]'
})
export class HighlightDirective implements OnChanges {
  @Input() searchText = ''

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  public ngOnChanges(): void {
    const element: any = this.el.nativeElement

    // If it isn't text to find, show the original content
    if (!this.searchText) {
      return
    }

    // Highlight the text in the child text nodes
    this.highlightMatches(element, this.searchText)
  }

  /**
  * For each text node highlight the match
  */
  private highlightMatches(element: HTMLElement, searchText: string): void {
    // Recorremos los nodos hijos del elemento
    Array.from(element.childNodes).forEach((node: ChildNode): void => {
      if (node.nodeType === Node.TEXT_NODE) {
        // If it's a text node, apply the highlight
        const text = node.textContent || ''
        if (text) {
          const highlightedText: string = text.replace(new RegExp(this.escapeRegExp(searchText), 'gi'), (match: string): string => {
            return `<mark>${match}</mark>`
          })
          const tempDiv: HTMLDivElement = document.createElement('div')
          tempDiv.innerHTML = highlightedText

          // Replace the text node to new highlight nodes
          Array.from(tempDiv.childNodes).forEach((childNode: ChildNode): void =>
            this.renderer.insertBefore(element, childNode, node)
          )
          this.renderer.removeChild(element, node)
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // If it isn't a text node, call recursively on child elements
        this.highlightMatches(node as HTMLElement, searchText)
      }
    })
  }

  /**
   * Escapes characters special for RegExp to avoid issues
   */
  private escapeRegExp(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }
}
