import { registerLocaleData } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { EffectsModule } from '@ngrx/effects'
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import { JhiConfigService, missingTranslationHandler, translatePartialLoader } from 'ng-jhipster'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { AppRoutingModule } from './app-routing.module'
import { DEBUG_INFO_ENABLED } from './app.constants'
import { HttpInterceptorModule } from './blocks/blocks.module'
import { PaginationConfig } from './blocks/config/uib-pagination.config'
import { BookModule } from './book/book.module'
import { bookEffects } from './book/redux/book-effects'
import { LanguageEffects } from './book/redux/effects/language.effects'
import { bookNavigationReducer } from './book/redux/reducers/book-navigation.reducer'
import { languageReducer } from './book/redux/reducers/language.reducer'
import { loadingReducer } from './book/redux/reducers/loading.reducer'
import { releaseReducer } from './book/redux/reducers/release.reducer'
import { HomeModule } from './home/home.module'
// jhipster-needle-angular-add-module-import JHipster will add new module here
import {
  CookieConsentComponent, ErrorComponent, FooterComponent, JhiMainComponent, PageRibbonComponent, ProfileService
} from './layouts'
import { LegalNoticeModule } from './legal-notice'
import { NotAuthorizedModule } from './not-authorized/not-authorized.module'
import { NotFoundModule } from './not-found/not-found.module'
import { PageViewsReleasesModule } from './page-views-releases'
import { PageViewsModule } from './page-views/page-views.module'
import { JhipsterSharedModule, UserRouteAccessService } from './shared'

registerLocaleData(localeDe)

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
    JhipsterSharedModule,
    HomeModule, PageViewsModule, PageViewsReleasesModule,
    LegalNoticeModule,
    BookModule,
    NotAuthorizedModule,
    NotFoundModule,
    HttpInterceptorModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
        deps: [JhiConfigService]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/api/', 'api/', `${window.location.origin}/api`],
        sendAccessToken: true
      }
    }),
    StoreModule.forRoot({ bookNavigation: bookNavigationReducer, releases: releaseReducer, languages: languageReducer, router: routerReducer, loading: loadingReducer }),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({ navigationActionTiming: NavigationActionTiming.PostActivation }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !!DEBUG_INFO_ENABLED, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([...bookEffects, LanguageEffects]),
    // jhipster-needle-angular-add-module JHipster will add new module here
  ],
  declarations: [
    JhiMainComponent,
    ErrorComponent,
    PageRibbonComponent,
    FooterComponent,
    CookieConsentComponent
  ],
  providers: [
    ProfileService,
    PaginationConfig,
    UserRouteAccessService,
    {
      provide: OAuthStorage,
      useValue: localStorage
    }
  ],
  bootstrap: [JhiMainComponent]
})
export class JhipsterAppModule { }
