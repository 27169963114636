import { Component, OnDestroy, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Craft } from '../../entities/craft.model'
import { CraftService } from './craft.service'
import { Store, select } from '@ngrx/store'
import { BookState } from '../redux/book.state'
import { ToggleSidebarAction, SetGalleryDataAction, SetPdfDataAction } from '../redux/actions/release.action'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SelectTableOfContentsAction } from '../redux/actions/book-navigation.action'
import { StateStorageService, TitleService } from '../../shared'
import { PdfCollectorService } from '../common/pdf-collector.service'
import { shouldShowSidebar } from '../redux/state'
import { contentContainerRequest$ } from '../common/content-container-request'
import { StopLoadingAction, StartLoadingAction } from '../redux/actions/loading.action'
import { Params } from '@angular/router'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FileContext, Material } from '../../entities'
import { concat } from 'lodash'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'
import { DeleteCustomTagsPipe } from '../../shared/pipe/delete-custom-tags.pipe'
import { MaterialCategory } from '../../entities/material-category.model'
import { MaterialService } from '../material/material.service'
import { Release } from '../../entities/release.model'
import { BaseComponent } from '../base/base.component'

@UntilDestroy()
@Component({
    selector: 'craft-detail',
    templateUrl: './craft-detail.component.html',
    styleUrls: ['./craft-detail.component.scss'],
    providers: [DeleteCustomTagsPipe]
})
export class CraftDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    public craft: Craft

    public selectedRelease: any

    public hasLoaded = false

    public hasImages = false

    public hasLinkedAttachmentCategories = false

    public hasMaterialCategories = false

    public hasFiles = false

    public activeIds: string[] = []

    public showSidebar$: Observable<boolean>

    public queryParams$: Observable<Params>

    public language: string

    public bookElement = 'material'

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>
    materialsCategoryInformation: Map<MaterialCategory, Material[]>

    constructor(
        private readonly craftService: CraftService,
        private readonly materialService: MaterialService,
        private readonly titleService: TitleService,
        store: Store<BookState>,
        private readonly pdfCollectorService: PdfCollectorService,
        private readonly sanitizer: DomSanitizer,
        private readonly deleteCustomTagsPipe: DeleteCustomTagsPipe,
        private readonly stateStorageService: StateStorageService
    ) {
        super(store)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))
        this.store.dispatch(new SelectTableOfContentsAction())

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {

                        const craftId = parseInt(params.id, 10)
                        const currentReleaseId = currentRelease.id
                        this.language = params.language

                        // We only need to call the backend if the craft has actually changed or a release is selected
                        if (!this.craft || craftId !== this.craft.originalId || selectedRelease) {

                            if (selectedRelease && selectedRelease.id !== currentReleaseId) {
                                return this.loadData(currentReleaseId, craftId, selectedRelease)
                            } else {
                                return this.loadData(currentReleaseId, craftId)
                            }

                        } else {
                            this.titleService.setTitle(this.deleteCustomTagsPipe.transform(this.craft.heading))
                        }
                    }
                }),
                untilDestroyed(this)

            ).subscribe((craft: Craft) => {
                this.handleCraft(craft)
            })
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    loadData(currentReleaseId: number, materialId: number, selectedRelease?: Release) {
        if (selectedRelease && selectedRelease.id !== currentReleaseId) {
            const response = this.loadCraft(currentReleaseId, materialId, selectedRelease.id)
            response.subscribe((room) => {
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        } else {
            const response = this.loadCraft(currentReleaseId, materialId)
            response.subscribe((room) => {
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        }
    }

    private loadCraft(releaseId: number, originalId: number, selectedReleaseId?: number) {
        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.craftService
            .find(releaseId, originalId, selectedReleaseId)

    }

    private loadMaterialsRelated(releaseId: number, originalId: number) {
        this.materialService.getMaterialsRelatedByCraft(releaseId, originalId).subscribe((response) => {
            this.materialsCategoryInformation = new Map<MaterialCategory, Material[]>()
            response.forEach((material) => {
                if (Array.isArray(material.materialCategories)) {
                    material.materialCategories.forEach((mc) => {
                        if (this.materialsCategoryInformation.has(mc)) {
                            this.materialsCategoryInformation.get(mc).push(material)
                        } else {
                            this.materialsCategoryInformation.set(mc, [material])
                        }
                    })
                }
            })
            this.hasMaterialCategories = this.materialsCategoryInformation.size > 0
        })
    }

    private handleCraft(craft: Craft) {
        this.craft = craft
        this.titleService.setTitle(this.deleteCustomTagsPipe.transform(this.craft.heading))
        this.hasLoaded = true
        this.store.dispatch(new SetGalleryDataAction(this.craft.images))

        let pdfFiles = this.pdfCollectorService.collectPdfs(this.craft)

        if (craft.hasProductList) {
            pdfFiles = concat(pdfFiles, craft.productLists.map((fc) => fc.file))
        }

        this.hasImages = this.craft.images.length > 0
        this.hasLinkedAttachmentCategories = this.craft.linkedAttachmentCategories.length > 0
        this.hasFiles = this.craft.pdfs.length > 0 || this.craft.files.length > 0
        this.hasMaterialCategories = this.craft.materialCategories.length > 0

        this.store.dispatch(new SetPdfDataAction(pdfFiles))

        this.store.dispatch(new StopLoadingAction())

        this.setInitiallyOpenedPanel()
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    public sanitizeImage(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url)
    }

    track(fileContext: FileContext) {
        return fileContext.id
    }

    setInitiallyOpenedPanel(): void {
        this.activeIds = []
        if (this.hasMaterialCategories) {
            this.activeIds = ['ngb-panel-material-categories']
        } else if (this.hasImages) {
            this.activeIds = ['ngb-panel-images']
        } else if (this.hasLinkedAttachmentCategories) {
            this.activeIds = ['ngb-panel-attachment-categories' + 0]
        } else if (this.hasFiles) {
            this.activeIds = ['ngb-panel-files']
        } else if (this.craft.hasProductList && this.craft.productLists.length > 0) {
            this.activeIds = ['ngb-panel-product-lists']
        }
    }
}
