import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core'

import { combineLatest, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { MaterialService } from './material.service'
import { Material, MaterialGroup } from '../../entities'
import { select, Store } from '@ngrx/store'
import { BookState } from '../redux/book.state'
import { ToggleSidebarAction, SetGalleryDataAction, SetPdfDataAction } from '../redux/actions/release.action'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { StateStorageService, TitleService } from '../../shared'
import { PdfCollectorService } from '../common/pdf-collector.service'
import { shouldShowSidebar } from '../redux/state'
import { contentContainerRequest$ } from '../common/content-container-request'
import { StopLoadingAction, StartLoadingAction } from '../redux/actions/loading.action'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'
import { DeleteCustomTagsPipe } from '../../shared/pipe/delete-custom-tags.pipe'
import { MaterialGroupService } from '../material-group'
import { Release } from '../../entities/release.model'
import { MaterialCategory } from '../../entities/material-category.model'
import { BaseComponent } from '../base/base.component'

@UntilDestroy()
@Component({
    selector: 'material-detail',
    templateUrl: './material-detail.component.html',
    styleUrls: ['./material-detail.component.scss'],
    providers: [DeleteCustomTagsPipe]
})
export class MaterialDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    public material: Material

    public hasProducts = false

    public hasRoomRelations = false

    public hasParentContents = false

    public hasMaterialCategories = false

    public hasImages = false

    public hasLinkedAttachmentCategories = false

    public hasFiles = false

    public hasLoaded = false

    public activeIds: string[] = []

    public showSidebar$: Observable<boolean>

    public language: string

    public bookElement = 'material'

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    public materialsCategoryInformation: Map<MaterialCategory, Material[]>

    public parent: MaterialGroup

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>

    constructor(
        private readonly materialService: MaterialService,
        private readonly titleService: TitleService,
        store: Store<BookState>,
        private readonly pdfCollectorService: PdfCollectorService,
        private readonly stateStorageService: StateStorageService,
        private readonly deleteCustomTagsPipe: DeleteCustomTagsPipe,
        private readonly materialGroupService: MaterialGroupService
    ) { super(store) }

    ngOnInit(): void {
        super.ngOnInit()
        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {

                        const materialId = parseInt(params.id, 10)
                        const currentReleaseId = currentRelease.id
                        this.language = params.language

                        // We only need to call the backend if the material has actually changed or a release is selected
                        if (!this.material || materialId !== this.material.originalId || selectedRelease) {
                            return this.loadData(currentReleaseId, materialId, selectedRelease)
                        } else {
                            this.titleService.setTitle(this.deleteCustomTagsPipe.transform(this.material.heading))
                        }

                        return of(this.material)
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe((material: Material) => {
                this.handleMaterial(material)
            })
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    loadData(currentReleaseId: number, materialId: number, selectedRelease?: Release) {
        if (selectedRelease && selectedRelease.id !== currentReleaseId) {
            const response = this.loadMaterial(currentReleaseId, materialId, selectedRelease.id)
            response.subscribe((material) => {
                this.loadParent(currentReleaseId, material.parentGroup.originalId, selectedRelease.id)
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        } else {
            const response = this.loadMaterial(currentReleaseId, materialId)
            response.subscribe((material) => {
                this.loadParent(currentReleaseId, material.parentGroup.originalId)
                this.loadMaterialsRelated(currentReleaseId, materialId)
            })
            return response
        }
    }

    private loadMaterial(releaseId: number, originalId: number, selectedReleaseId?: number) {

        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.materialService
            .find(releaseId, originalId, selectedReleaseId)
    }

    private loadMaterialsRelated(releaseId: number, originalId: number) {
        this.materialService.getMaterialsRelated(releaseId, originalId).subscribe((response) => {
            this.materialsCategoryInformation = new Map<MaterialCategory, Material[]>()
            response.forEach((material) => {
                if (Array.isArray(material.materialCategories)) {
                    material.materialCategories.forEach((mc) => {
                        if (this.materialsCategoryInformation.has(mc)) {
                            this.materialsCategoryInformation.get(mc).push(material)
                        } else {
                            this.materialsCategoryInformation.set(mc, [material])
                        }
                    })
                }
            })
            this.hasMaterialCategories = this.materialsCategoryInformation.size > 0
        })
    }

    private loadParent(releaseId: number, originalId: number, selectedReleaseId?: number) {
        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())
        this.materialGroupService.find(releaseId, originalId, selectedReleaseId).subscribe((mg) => this.parent = mg)
    }

    private handleMaterial(material: Material) {
        this.material = material
        this.hasProducts = this.material && this.material.products && this.material.products.length > 0
        this.hasRoomRelations = this.material.materialRoomRelations.length > 0
        this.hasParentContents = !!this.material.parentContents
        this.hasImages = this.material.images.length > 0
        this.hasLinkedAttachmentCategories = this.material.linkedAttachmentCategories.length > 0
        this.hasFiles = this.material.pdfs.length > 0 || this.material.files.length > 0

        this.titleService.setTitle(this.deleteCustomTagsPipe.transform(this.material.heading))

        this.store.dispatch(new SetGalleryDataAction(this.material.images))

        const files = this.pdfCollectorService.collectPdfs(this.material)

        this.store.dispatch(new SetPdfDataAction(files))

        this.hasLoaded = true
        this.store.dispatch(new StopLoadingAction())

        this.setInitiallyOpenedPanel()
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    setInitiallyOpenedPanel(): void {
        this.activeIds = []

        if (this.hasMaterialCategories) {
            this.activeIds = ['ngb-panel-material-categories']
        } else if (this.hasRoomRelations) {
            this.activeIds = ['ngb-panel-usage']
        } else if (this.hasParentContents) {
            this.activeIds = ['ngb-panel-parent-contents']
        } else if (this.hasProducts) {
            this.activeIds = ['ngb-panel-products']
        } else if (this.hasImages) {
            this.activeIds = ['ngb-panel-images']
        } else if (this.hasLinkedAttachmentCategories) {
            this.activeIds = ['ngb-panel-attachment-categories' + 0]
        } else if (this.hasFiles) {
            this.activeIds = ['ngb-panel-files']
        }
    }
}
