import { Component, OnInit } from '@angular/core'
import { untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { BookState } from '../redux/book.state'
import { selectQueryParams } from '../redux/state'
import { Observable } from 'rxjs'
import { Params } from '@angular/router'

@Component({
    template: ''
})
export abstract class BaseComponent implements OnInit {
    public queryParams: any = {}

    constructor(
        protected readonly store: Store<BookState>
    ) { }

    public ngOnInit(): void {
        this.getQueryParams()
            .pipe(
                untilDestroyed(this))
            .subscribe((params: any): void => {
                this.queryParams = params
            })
    }

    protected getQueryParams(): Observable<Params> {
        return this.store.pipe(
            select(selectQueryParams),
            filter((params: Params): boolean => !!params))
    }
}
