import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Material } from '../../entities/material.model'
import { SERVER_API_URL } from '../../app.constants'
import { map } from 'rxjs/operators'

@Injectable()
export class MaterialService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number, selectedReleaseId?: number): Observable<Material> {
        let params = new HttpParams()
        if (selectedReleaseId) {
            params = params.append('originalReleaseId', '' + selectedReleaseId)
        }

        return this.httpClient
            .get<Material>(`${SERVER_API_URL}/api/releases/${releaseId}/materials/${id}`, { params })
            .pipe(map((material) =>
                Object.assign(new Material(), material)
            ))
    }

    getMaterialsRelated(releaseId: number, originalId: number): Observable<Material[]> {
        return this.httpClient
            .get<Material[]>(`${SERVER_API_URL}/api/releases/${releaseId}/materials/${originalId}/related`)
    }

    getMaterialsRelatedByCraft(releaseId: number, originalId: number): Observable<Material[]> {
        return this.httpClient
            .get<Material[]>(`${SERVER_API_URL}/api/releases/${releaseId}/crafts/${originalId}/related`)
    }
}
