import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators'
import { Product } from '../../entities/product.model'
import { Release } from '../../entities/release.model'
import { ResponseWrapper, TitleService } from '../../shared'
import { ProductService } from '../product/product.service'
import { SetPdfDataAction } from '../redux/actions/release.action'
import { BookState } from '../redux/book.state'
import { selectQueryParams } from '../redux/state'
import { getCurrentRelease } from '../redux/state/release.state'
import { RelatedResult } from './related-result.model'
import { SearchResult } from './search-result.model'
import { SearchService } from './search.service'
import { MaterialCategoryDropdown } from './material-category-dropdown.interface'

@UntilDestroy()
@Component({
    selector: 'search-results',
    templateUrl: 'search-results.component.html',
    styleUrls: ['search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

    public query = ''
    public page = 1
    public nextQuery = ''
    public queryParamsWithoutQuery // Used to preserve the release ID if necessary

    public totalResults
    public results: SearchResult[] = []
    public materialCategories: MaterialCategoryDropdown[] = null
    public materialCategoryOriginalId

    public relatedResults: RelatedResult[]

    public pageSize = 10
    public hasError = false

    public currentRelease$: Observable<Release>
    public queryParams$: Observable<Params>

    public activeId = null

    private pageChange$ = new Subject<number>()

    private currentReleaseId = null

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly searchService: SearchService,
        private readonly titleService: TitleService,
        private readonly store: Store<BookState>,
        private readonly productService: ProductService
    ) {
        this.currentRelease$ = this.store.pipe(select(getCurrentRelease), filter((release) => !!release))
    }

    ngOnInit() {
        this.queryParams$ = this.store.pipe(
            select(selectQueryParams),
            filter((params) => !!params),
            map((params) => ({ q: params.q, mcoid: params.mcoid, p: params.p || 1, ...params }))
        )
        this.searchService.getMaterialCategories().subscribe((response) => this.materialCategories = response)
        const searchQueryChange$ = this.queryParams$.pipe(map((queryParams) => ({ q: queryParams.q, mcoid: queryParams.mcoid })), distinctUntilChanged())

        // keeps track of the current release we are searching
        const mainReleaseId$ = this.currentRelease$.pipe(map((release) => release.id))

        mainReleaseId$
            .pipe(untilDestroyed(this))
            .subscribe((releaseId) => this.currentReleaseId = releaseId)

        this.queryParams$.pipe(
            untilDestroyed(this)
        ).subscribe((params) => {
            this.query = params.q
            this.materialCategoryOriginalId = params.mcoid
            this.page = params.p
            this.nextQuery = this.query

            // Remove search query from query params
            this.queryParamsWithoutQuery = Object.assign({}, params)
            delete this.queryParamsWithoutQuery.q
            delete this.queryParamsWithoutQuery.mcoid
            delete this.queryParamsWithoutQuery.p
            delete this.queryParamsWithoutQuery.selectedRelease

            this.titleService.setSearchPageTitle(this.query)
        })

        // Skip the first page change event because NgbPagination fires even for the initial value
        this.pageChange$.pipe(
            skip(1),
            distinctUntilChanged(),
            untilDestroyed(this)
        ).subscribe((page) => this.search(page))

        // mainReleaseId$ will emit only once in most cases, but it's our backup if there is no selected release in the URL
        // We can't rely on releaseToSearch$ here since that depends on the query params as well. We avoid firing two
        // HTTP requests this way.
        combineLatest([this.queryParams$, mainReleaseId$]).pipe(
            // set the active tab
            tap(([params, mainReleaseId]) => {
                // We have to make sure that it's always a number, otherwise NgbNav's matching logic won't mark the active release correctly
                this.activeId = +(params.selectedRelease ? params.selectedRelease : mainReleaseId)
            }),
            switchMap(([params, mainReleaseId]) => {
                const releaseId = params.selectedRelease ? params.selectedRelease : mainReleaseId
                return this.searchService.query(releaseId, params.q, params.mcoid, params.p, this.pageSize).pipe(catchError(() => of(null)))
            }),
            untilDestroyed(this)
        ).subscribe((res: ResponseWrapper) => {
            if (res === null) {
                this.hasError = true
                this.results = []
                this.totalResults = undefined
            } else {
                this.totalResults = res.headers.get('X-Total-Count')
                this.results = res.json
                this.hasError = false

                const files = this.results
                    .filter((result) => !!result.file && result.file.type === 'application/pdf')
                    .map(((result) => result.file))

                this.store.dispatch(new SetPdfDataAction(files))

            }

        })

        // We only need to fetch the related results if the search query changes, not if the page or the selected related release changes
        combineLatest([searchQueryChange$, mainReleaseId$]).pipe(
            switchMap(([params, releaseId]) => {
                return this.searchService.getRelatedResults(releaseId, params.q, params.mcoid).pipe(catchError(() => of(null)))
            }),
            untilDestroyed(this)
        ).subscribe((res: ResponseWrapper) => {
            if (res !== null) {
                this.relatedResults = res.json
            }

        })

    }

    changePage(page) {
        this.pageChange$.next(page)
    }

    search(page: number) {
        this.document.body.scrollIntoView()

        this.router.navigate(['search'], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
            queryParams: {
                q: this.nextQuery,
                mcoid: this.materialCategoryOriginalId,
                p: page
            }
        })
    }

    searchRelated(releaseId: number) {
        this.activeId = releaseId
        this.router.navigate(['search'], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
            queryParams: {
                selectedRelease: this.currentReleaseId !== releaseId ? releaseId : undefined,
                q: this.nextQuery,
                p: 1
            }
        })
    }

    materialCategorySelected(event) {
        this.materialCategoryOriginalId = event
        this.search(this.page)
    }

    buildRouterLink(result: SearchResult): string[] {
        // in case of a product, the navigation will be triggert from a click event and not the routerlink
        if (result.type === 'Product') {
            return
        }

        const release = result.release

        return ['/', release.projectFragment, release.languageFragment, release.construction ? 'construction' : 'modernization', ...result.url]

    }

    getQueryParams(result: SearchResult): Params {
        this.queryParamsWithoutQuery.releaseId = btoa(result.release.releaseId.toString())
        this.queryParamsWithoutQuery.searchText = this.query
        return this.queryParamsWithoutQuery
    }

    navigateTo(result: SearchResult): void {
        const release = result.release
        if (result.type === 'Product') {
            this.productService.find(release.releaseId, result.originalId)
                .subscribe((product: Product) => {
                    this.router.navigate(
                        [
                            '/',
                            release.projectFragment,
                            release.languageFragment,
                            release.construction ? 'construction' : 'modernization',
                            'manufacturer',
                            '' + product.manufacturer.originalId
                        ], {
                        queryParams: {
                            searchText: this.query
                        }
                    })
                })
        }
    }
}
